import OrderSummary, { OrderSummarySkeleton } from '@/components/order/OrderSummary';
import ProductCard, { ProductCardSkeleton } from '@/components/products/productCard/ProductCard';
import {
  FF_DISABLE_OPEN_ORDER_MODAL,
  FF_ENABLE_PREFILL_PRODUCT_QUANTITY,
  FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES,
  FF_ENABLE_SHOPIFY_CHECKOUT,
  FF_REFUND_NON_REFUND_CREDIT,
  FF_USE_NEW_EDIT_ORDER_ENDPOINT,
  FF_USE_SHOPIFY_PRODUCT_IMAGES,
} from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useShippingPrice from '@/hooks/useShippingPrice';
import { Logger } from '@/utils/logger';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { ToastConfirmModal, toastOptions } from '@/components/common/toastConfirm';
import settings from '@/constants/constants';
import '../../scss/patientRefill.scss';
import { AuthService } from '@/services/authentication.service';
import { PaymentService } from '@/services/payment.service';
import { OrderService } from '@/services/order.service';
import { getPatientDiscountCreditLineItems } from '@/services/patientCredit.service';
import RefillCheckoutButton from '@/components/patient/RefillCheckoutButton';
import { Box, styled } from '@mui/material';
import { numberFormat } from '@/utils/helpers';
import BrainfishHelpDeskPopup from '@/components/brainfish/HelpDeskPopup';
import EnquiryLoadingDialog from '@/components/patient/enquiryModal/EnquiryLoadingDialog';
import EnquiryProductDialog from '@/components/patient/enquiryModal/EnquiryProductDialog';
import RequestConfirmationDialog from '@/components/patient/enquiryModal/requstConfirmationDialog/RequestConfirmationDialog';
import useCreatePatientRescriptRequest from '@/hooks/patient/useCreatePatientRescriptRequest';
import EnquiryErrorDialog from '@/components/patient/enquiryModal/EnquiryErrorDialog';
import { GENERATIVE, OUT_OF_STOCK } from '@/components/patient/enquiryModal/common';
import { BRAZE_CONTENT_CARD } from '@/assets/data/enums';
import SinglePageContentCard from '@/components/braze/SinglePageContentCard';
import getProductImageUrl from '@/utils/getProductImageUrl';
import MontuBrandWrapper from '@/components/common/MontuBrandWrapper';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';
import { GoogleAnalyticsEventName } from '@/types';
import useAddressValidation from '@/hooks/user/useAddressValidation';
import { FORMULATION_ID_DEVICE, getProductFilterTypeFromFormulationId } from '@/hooks/patient/refill/util';

const logger = new Logger('PatientRefill');

////////////////////
// Style Components
////////////////////

const ResponsiveTwoColContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '1.5rem',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

const ProductsColumn = styled(Box)`
  flex: 1;
`;

const DesktopOnly = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}));

const MobileOnly = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const MobileFixedToBottom = styled(MobileOnly)`
  position: sticky;
  bottom: 1rem;
  width: 100%;
  padding: 0;
  left: 5%;
`;

////////////////
// Utility Fns
////////////////

// Note: Devices appear to be returning "remainingUnits" camelCase rather than "remaining_units". This is why devices
// are allowed to be added indefinitely since "undefined" comparisons for quantity exceeding allowed is always false.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Greater_than#comparing_boolean_null_undefined_nan
export const deriveMaxAllowedQuantity = (availableProduct) => {
  if (availableProduct.formulation_id == FORMULATION_ID_DEVICE) {
    return 10;
  }

  //TODO: Fix this typo on the backend, then remove this line
  const remainingUnits = Math.max(availableProduct.remainingUnits || 0, availableProduct.remaining_units || 0);

  return Math.min(remainingUnits, availableProduct.quantity_base_order);
};

const transformAvailableProductToSelectedProductData = (availableProduct) => ({
  quantity_original: deriveMaxAllowedQuantity(availableProduct),
  product_name: availableProduct.name,
  repeats: availableProduct.repeats,
  price: availableProduct.price,
  notAddable: availableProduct.notAddable,
  remainingUnits: availableProduct.remaining_units,
  interval: availableProduct.interval,
  is_out_of_stock: availableProduct.is_out_of_stock,
  short_name: availableProduct.short_name,
  is_concession: availableProduct.is_concession,
});

export const transformSelectedProductToReplaceOrderProduct = (selectedProduct) => ({
  productId: selectedProduct.data.product_id,
  quantity: selectedProduct.data.quantity,
  // TODO avoid sending repeats, interval, remainingUnits from the front end
  repeats: selectedProduct.data.repeats,
  interval: selectedProduct.data.interval,
  remainingUnits: selectedProduct.data.remainingUnits,
});

export const isProductOutOfStock = (product) => {
  return Boolean(product.is_out_of_stock || product.reasoning_toggle);
};

export const isProductDiscontinued = (product) => {
  return Boolean(product.is_generative_scripting);
};

export const canPrefillProduct = (product) => {
  return Boolean(
    product && !(product.remaining_units === 0 || isProductOutOfStock(product) || isProductDiscontinued(product)),
  );
};

function sortByOrderProducts(orderProducts) {
  return (a, b) =>
    orderProducts.some((op) => op.id === b.id) - orderProducts.some((op) => op.id === a.id) || a.id - b.id;
}

/**
 * Transforms the products array into three lists: non device prescription products, device prescription products, device non prescription products.
 * Each of these lists is then also also sorted
 * 1. Non device prescription products are sorted by all products found in orderProducts first, then products not found in orderProducts, then products that are interval locked and lastly products that are out of stock. Any product with no remaining units or where the prescription is expired is removed from this list.
 * 2. Device prescription products are sorted by all products found in orderProducts first, then products not found in orderProducts. This list does not include all devices avaiable to the patient only those that were prescribed.
 * 3. Device non prescription products are sorted by all products found in orderProducts first, then products not found in orderProducts. All out of stock products in this list are removed.
 * All three lists are then concatenated together and returned, in the order of non device prescription products, device prescription products, device non prescription products.
 * Diagram explaining this logic https://montugroup.atlassian.net/wiki/spaces/Eng/pages/191397901/Logic+for+displaying+products+on+V3
 */
export const transformProductList = (products, orderProducts) => {
  // All non device prescription products sorted by those that are in orderProducts first
  const nonDevicePrescriptionProducts = products
    .filter(
      (product) =>
        product.formulation_id !== FORMULATION_ID_DEVICE && product.remaining_units > 0 && product.is_expired === false,
    )
    .sort(sortByOrderProducts(orderProducts))
    .sort((a, b) => a.isProductLocked - b.isProductLocked)
    .sort((a, b) => isProductOutOfStock(a) - isProductOutOfStock(b));

  // All device prescription products
  const devicePrescriptionProducts = products
    .filter((product) => product.formulation_id === FORMULATION_ID_DEVICE && !!product.prescription_id)
    .sort(sortByOrderProducts(orderProducts))
    .sort((a, b) => b.campaign_discount_price - a.campaign_discount_price);

  // All device non prescription products
  const deviceNonPrescriptionProducts = products
    .filter(
      (product) =>
        product.formulation_id === FORMULATION_ID_DEVICE && !product.prescription_id && !isProductOutOfStock(product),
    )
    .sort(sortByOrderProducts(orderProducts))
    .sort((a, b) => b.campaign_discount_price - a.campaign_discount_price);

  return [...nonDevicePrescriptionProducts, ...devicePrescriptionProducts, ...deviceNonPrescriptionProducts];
};

const filterOutUnavailableAndLockedProducts = (selectedProducts, availableProducts) => {
  if (selectedProducts.length === 0 || availableProducts.length === 0) {
    return [];
  }
  return selectedProducts.filter((selectedProduct) =>
    availableProducts.some(
      (availableProduct) =>
        availableProduct?.id === selectedProduct?.data?.product_id && !availableProduct?.isProductLocked,
    ),
  );
};

const getInitialDataState = (user, orderId) => ({
  orderId,
  pms_order_code: null,
  pms_client: null,
  patient_name: `${user.first_name} ${user.last_name}`,
  currentCreditDiscounts: [],
  patientCredit: null,
  order: null,
  baseOrder: '',
  productList: [],
  edit: false,
  patientDiscounts: [],
  orderDiscounts: [],
  campaign_discount_price: false,
});

export function getCheckoutData(selectedProducts, creditsDiscounts = [], shippingCosts, isShopifyCheckoutEnabled) {
  const products =
    selectedProducts
      .filter((p) => p.data.quantity >= 1)
      .map((selectedProduct) => ({
        id: selectedProduct.data.product_id,
        name: selectedProduct.data.product_name,
        price: selectedProduct.data.price,
        quantity: selectedProduct.data.quantity,
      })) || [];

  const totalNumberOfProducts = products.reduce((total, { quantity }) => total + quantity, 0);

  const credits = creditsDiscounts
    .filter((creditOrDiscount) => !creditOrDiscount.isDiscount)
    .map((credit) => ({
      id: credit.id,
      amount: parseFloat(credit.amount),
      display: credit.name,
    }));

  let shopifyShippingDiscount;
  const discounts = creditsDiscounts
    .filter((creditOrDiscount) => {
      if (!creditOrDiscount.isDiscount) return false;

      if (isShopifyCheckoutEnabled && creditOrDiscount.discountType === settings.discountType.DISPENSING_FEE) {
        shopifyShippingDiscount = creditOrDiscount;
        return false;
      }

      return true;
    })
    .map((discount) => ({
      id: discount.id,
      amount: parseFloat(discount.amount),
      display: discount.name,
    }));

  let shippingCalculationNote;
  if (shopifyShippingDiscount && shopifyShippingDiscount.discountPercentage) {
    shippingCalculationNote = `Shipping fee calculated at checkout, you are eligible for a ${Math.floor(
      shopifyShippingDiscount.discountPercentage,
    )}% discount.`;
  }
  const productPrice = products.reduce((total, { price, quantity }) => total + price * quantity, 0);
  const creditPrice = credits.reduce((total, { amount }) => total + amount, 0);
  const discountPrice = discounts.reduce((total, { amount }) => total + amount, 0);
  const subTotalPrice = productPrice - discountPrice - creditPrice + shippingCosts.shipping;
  const totalPrice = productPrice - discountPrice - creditPrice + shippingCosts.shippingTotal;

  return {
    products,
    totalNumberOfProducts,
    totalPrice,
    subTotalPrice,
    discounts,
    credits,
    shippingCosts: {
      shipping: shippingCosts.shipping,
      shippingGst: shippingCosts.shippingGst,
    },
    shippingCalculationNote,
  };
}

export const calculateInitialQuantity = (orderProduct, maxQuantity) => {
  // The initial quantity should be the max the patient allowed
  // If their remaining quantity from a previous script is somehow more than their max from a new script, pick the lowest option.
  return Math.min(orderProduct.quantity, maxQuantity);
};

export const generatePrefilledProducts = (orderData, preLoadProducts, enablePrefillQuantity) => {
  const initialSelectedProducts = [];

  const availableProducts = transformProductList(orderData.products, orderData.orderProducts || []);

  if (preLoadProducts && preLoadProducts.length > 0) {
    preLoadProducts.forEach((productId) => {
      const product = availableProducts.find((x) => x.id === productId);

      const canPrefill = canPrefillProduct(product);

      const quantity = enablePrefillQuantity ? deriveMaxAllowedQuantity(product) : 1;
      if (canPrefill) {
        initialSelectedProducts.push({
          id: productId,
          data: {
            product_id: productId,
            quantity,
            ...transformAvailableProductToSelectedProductData(product),
          },
        });
      }
    });
  } else {
    orderData.orderProducts.forEach((orderProduct) => {
      const productId = orderProduct.id; // This is actually a Product id, not an OrderProduct id from the backend
      const product = availableProducts.find((x) => x.id === productId);
      const canPrefill = canPrefillProduct(product);

      if (canPrefill) {
        const selectedProductData = transformAvailableProductToSelectedProductData(product);
        initialSelectedProducts.push({
          id: productId,
          data: {
            product_id: productId,
            quantity: calculateInitialQuantity(orderProduct, selectedProductData.quantity_original),
            ...selectedProductData,
          },
        });
      }
    });
  }

  // Sanity test the selected products when the available products updates, populate with default inventory if the
  // array would be empty...
  return filterOutUnavailableAndLockedProducts(initialSelectedProducts, orderData.products);
};

///////////////
// Component
///////////////

// Ticket: https://montugroup.atlassian.net/jira/software/c/projects/B2C/boards/19?modal=detail&selectedIssue=B2C-822&assignee=640e59831273131f2adfc2b7

function PatientRefill(props) {
  // Indicate if data has been fetched
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { user } = AuthService.getUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCheckProductEnquiryLoading, setIsCheckProductEnquiryLoading] = useState(false);
  const [isEnquiryProductDialogOpen, setIsEnquiryProductDialogOpen] = useState(false);
  const [productEnquiryStatus, setProductEnquiryStatus] = useState();
  const [selectedProductId, setSelectedProductId] = useState();
  const [isRequestConfirmationDialogOpen, setIsRequestConfirmationDialogOpen] = useState(false);
  const [hasEnquiryError, setHasEnquiryError] = useState(false);
  const { validateParcelLocker } = useAddressValidation();

  const [isCreateRescriptRequestLoading, setIsCreateRescriptRequestLoading] = useState();

  const createPatientRescriptRequest = useCreatePatientRescriptRequest();
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();

  // Using Feature Flags in this page
  const { flags, loading: ffLoading } = useFeatureFlags();
  const ifRefundTrue = !ffLoading && flags[FF_REFUND_NON_REFUND_CREDIT];
  const ffCanReplaceInvoiceOrderProducts = flags[FF_USE_NEW_EDIT_ORDER_ENDPOINT];
  const ffEnableProductIssueEnquiries = flags[FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES];
  const useShopifyProductImages = flags[FF_USE_SHOPIFY_PRODUCT_IMAGES] || ffDefaults[FF_USE_SHOPIFY_PRODUCT_IMAGES];
  const isShopifyCheckoutEnabled = flags[FF_ENABLE_SHOPIFY_CHECKOUT];

  const shippingCosts = useShippingPrice();

  // Data appears to be a collection of multiple different sets of states
  const [data, setData] = useState(getInitialDataState(user, props.match.params.id));
  const [selectedProducts, setSelectedProducts] = useState([]);

  const creditDiscounts = getPatientDiscountCreditLineItems(
    {
      selectedProducts,
      patientDiscounts: data.patientDiscounts,
      currentCreditDiscounts: data.currentCreditDiscounts,
      patientCredit: data.patientCredit,
      orderDiscounts: data.orderDiscounts,
    },
    ifRefundTrue,
    shippingCosts.shipping,
    shippingCosts.shippingGst,
  );

  const isDischarge = Boolean(data.patient?.is_discharge);
  const hasSelectedAnyProducts = selectedProducts.length > 0;
  const isCheckoutDisabled = isDischarge || isSubmitting || !hasSelectedAnyProducts;

  const isProductCardsDisabled = (product) => {
    if (isSubmitting) {
      return true;
    }

    return ffEnableProductIssueEnquiries && (product.isGenerativeScripting || product.isOutOfStock);
  };
  const checkoutData = getCheckoutData(selectedProducts, creditDiscounts, shippingCosts, isShopifyCheckoutEnabled);

  const unknownSupplierName = 'Unknown Supplier';

  const availableProducts = useMemo(
    () => transformProductList(data.productList, data.orderProducts || []),
    [data.productList, data.orderProducts],
  );

  const generateGoogleAnalyticsEcommercePayload = (products) => ({
    currency: 'AUD',
    value: products.reduce((acc, item) => acc + item.price * item.quantity, 0),
    items: products.map((product) => ({
      name: product.product_name,
      quantity: product.quantity,
      price: product.price,
    })),
  });

  // TODO: Use react-query and hooks for network
  // TODO: Error handling
  const initialize = async () => {
    try {
      const refillResponse = await OrderService.getOrderForPatientRefill(user.id, props.match.params.id || false);
      const refillData = refillResponse.data;

      localStorage.setItem('moreUserData', JSON.stringify(refillData));

      let preLoadProducts;
      // This is if you click "re-order" from orders page

      if (flags[FF_ENABLE_PREFILL_PRODUCT_QUANTITY]) {
        preLoadProducts =
          props.location.state?.preLoadProducts ??
          (!refillData?.patientHasPurchases
            ? refillData.products.filter((p) => Boolean(p.prescription_id)).map((p) => p.id)
            : []);
      } else {
        preLoadProducts = props.location.state?.preLoadProducts;
      }
      // Set selected products first, so that the product rows will have the correct quantity on first non-loading render.
      const prefilledProducts = generatePrefilledProducts(
        refillData,
        preLoadProducts,
        flags[FF_ENABLE_PREFILL_PRODUCT_QUANTITY],
      );

      setSelectedProducts([...prefilledProducts]);

      setFetchedData(refillData);
      setIsDataFetched(true);
      if (flags[FF_ENABLE_SHOPIFY_CHECKOUT]) {
        sendGoogleAnalyticsGTagEvent(
          GoogleAnalyticsEventName.VIEW_CART,
          generateGoogleAnalyticsEcommercePayload(prefilledProducts.map(({ data }) => ({ data }))),
        );
      }
    } catch {
      logger.error('Error initializing refill data');
      setIsDataFetched(true);
    }
  };

  // This will drive the <ProductCard /> components
  const productCardProducts = availableProducts.map((availableProduct) => ({
    id: availableProduct.id,
    name: availableProduct.name,
    price: availableProduct.price,
    supplierName: availableProduct.Suppliers[0]?.supplier_name || unknownSupplierName,
    isOutOfStock: isProductOutOfStock(availableProduct),
    reasoningToggle: availableProduct.reasoning_toggle,
    isGenerativeScripting: !!availableProduct.is_generative_scripting,
    productType: getProductFilterTypeFromFormulationId(availableProduct.formulation_id),
    imageUrl: getProductImageUrl(useShopifyProductImages, availableProduct.image_url, availableProduct.ProductImages),
    shortName: availableProduct.short_name, // TODO
    thcStrengthForDisplay: availableProduct.thc_strength_for_display,
    cbdStrengthForDisplay: availableProduct.cbd_strength_for_display,
    remainingUnits: availableProduct.remaining_units,
    repeats: availableProduct.repeats,
    isIntervalLocked: availableProduct.isProductLocked,
    intervalEndDate: availableProduct.intervalEndDate,
    maxAllowedQuantity: deriveMaxAllowedQuantity(availableProduct),
    isExpired: availableProduct.is_expired,
    prescriptionId: availableProduct.prescription_id,
    isCampaignDiscountPrice: availableProduct.campaign_discount_price,
    productFormulation: availableProduct.ProductFormulation,
  }));

  // Initial page load data. We pre-fill the refill page to match with an existing order.
  useEffect(() => {
    initialize();
    logger.info(`userId=${user.id} loaded refill page`);
  }, []);

  // Seed the initial state from API response...
  const setFetchedData = (orderData) => {
    setData({
      ...data,
      patient: orderData.patient,
      pms_client: orderData.patient.client_code,
      productList: orderData.products,
      orderProducts: orderData.orderProducts,
      orderDiscounts: orderData.orderDiscounts,
      currentCreditDiscounts: orderData.currentCreditDiscounts,
      patientDiscounts: orderData.patientDiscounts,
      patientCredit: orderData.patientCredit,
      patient_preferred_pharmacy: orderData.patient.pharmacy_id,
      ...(orderData.order
        ? {
            order: orderData.order,
            pms_order_code: orderData.order.pms_order_code,
          }
        : {
            patientGP: orderData.patientGP,
          }),
    });
  };

  const trackQuantityChange = (event, product) => {
    if (flags[FF_ENABLE_SHOPIFY_CHECKOUT]) {
      sendGoogleAnalyticsGTagEvent(
        event === 'plus' ? GoogleAnalyticsEventName.ADD_TO_CART : GoogleAnalyticsEventName.REMOVE_FROM_CART,
        generateGoogleAnalyticsEcommercePayload([product]),
      );
    }
  };

  // Handle quantity change
  // @param {event} "plus" | "minus"
  // @param {id} number - Row index
  const handleQuantity = async (event, id) => {
    const rows = [...selectedProducts];
    const productRow = rows.find((row) => row.id === id);

    const product = data.productList.find((x) => x.id === productRow.data.product_id);
    if (!product) {
      // Annoying - why can't we remove empty row? selectedProducts may contain products that are not in productList
      toast.error('No product chosen!', { toastId: 'no-product-chosen' });
      return;
    }

    const previousQuantity = productRow.data.quantity || 0;
    const newQuantity = previousQuantity + (event === 'plus' ? 1 : -1);
    if (newQuantity < 1) {
      deleteRow(id);
      trackQuantityChange(event, { ...productRow.data, quantity: 0 });
      return;
    }
    if (product.formulation_id !== FORMULATION_ID_DEVICE && newQuantity > productRow.data.quantity_original) {
      toast.warn('Maximum Order Quantity reached!', { toastId: 'max-quantity-reached' });
      return;
    }

    productRow.data.quantity = newQuantity; // Mutates state directly
    setSelectedProducts(rows);
    trackQuantityChange(event, productRow.data);
  };

  // Called inside <Product /> with selectedProducts
  const addRow = async (selectedProducts, newItem) => {
    setSelectedProducts([...selectedProducts, newItem]);
    trackQuantityChange('plus', newItem.data);
  };

  // Called after a user decrements count to 0. This gets passed to <Product> but not used in there
  const deleteRow = (id) => {
    const rows = selectedProducts.filter((itm) => {
      return itm.id !== id;
    });
    setSelectedProducts(rows);
  };

  const placeOrderShopify = async () => {
    const { order } = data;

    const productsPayload = selectedProducts
      .filter((selectedProduct) => {
        const product = data.productList.find(({ id }) => id === selectedProduct.data.product_id);
        return (product && product.remaining_units > 0) || product.formulation_id === FORMULATION_ID_DEVICE;
      })
      .map(({ data: { quantity, product_id } }) => ({
        quantity,
        productId: product_id,
      }));

    const { checkoutRedirectUrl, error: responseError } = await PaymentService.getCheckoutRedirectUrl(
      productsPayload,
      order?.id,
      order?.order_code,
    );

    if (checkoutRedirectUrl) {
      window.location.replace(checkoutRedirectUrl);
    } else {
      setIsSubmitting(false);
      logger.error(responseError || 'Failed to retrieve checkoutUrl');
      toast.error('Something went wrong, please try again');
    }
  };

  const placeOrderLegacy = async (edit = false) => {
    const now = moment(new Date());
    const nowValue = now.valueOf();
    const nowTz = now.format('Z');
    const orderData = {
      transactionType: settings.transactionType.CREDIT_CARD,
      patient: data.patient.id,
      order_date: nowValue,
      order_code: '',
      tz: nowTz,
      request_type: 'refill',
      ...(data.order
        ? {
            pharmacy: edit ? data.order.pharmacy_id : data.patient_preferred_pharmacy,
            condition: data.order.medical_condition,
            prescription: data.order.prescription_notes,
            pms_order_code: data.order.pms_order_code,
            discount: data.order.discount,
            base_order_id: data.order.id,
            gp: data.order.GeneralPractitioner.user_id,
          }
        : {
            gp: data.patientGP.user_id,
            pharmacy: data.patient_preferred_pharmacy,
          }),
      pms_client: data.pms_client,
      products: selectedProducts
        .filter((x) => {
          const prdt = data.productList.find((product) => product.id === x.data.product_id);
          if ((prdt && prdt.remaining_units > 0) || prdt.formulation_id === FORMULATION_ID_DEVICE) {
            return true;
          }
          return false;
        })
        .map((x) => ({
          quantity: x.data.quantity,
          product_id: x.data.product_id,
          interval: x.data.interval,
          remainingUnits: x.data.remainingUnits,
          repeats: x.data.repeats,
        })),
    };

    if (orderData.products.length === 0) {
      toast.error('No Products Selected', { toastId: 'no-products' });
      return setIsSubmitting(false);
    }

    const matchLegacyData = {
      ...data,
      productList: data.productList.map((availableProduct) => ({
        ...availableProduct,
        supplier_id: availableProduct.Suppliers?.[0]?.id || null,
        supplier_name: availableProduct.Suppliers?.[0]?.supplier_name || 'Others',
      })),
    };

    props.history.push({
      pathname: '/paymentcheckout',
      state: { selectedData: { ...matchLegacyData, creditDiscounts, selectedProducts }, orderData, edit }, // your data array of objects
    });
  };

  const placeOrder = async (orderId) => {
    if (flags[FF_ENABLE_SHOPIFY_CHECKOUT]) {
      sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.CLICK_CTA, {
        link_id: 'cart_checkout_button',
      });
      await placeOrderShopify();
    } else {
      await placeOrderLegacy(orderId);
    }
  };

  const handleCheck = async (orderId) => {
    try {
      if (!flags[FF_ENABLE_SHOPIFY_CHECKOUT] && orderId && ffCanReplaceInvoiceOrderProducts) {
        // Hack to properly replace an orders products and re-calculate discounts. Ideally, we will address the underlying
        // root cause throughout the system everywhere an order is mutated.
        logger.info('Replacing invoice order products');
        const products = selectedProducts.map(transformSelectedProductToReplaceOrderProduct);
        await OrderService.replaceInvoiceOrderProducts(orderId, products);
      }

      const notAddableProducts = selectedProducts.filter((x) => x.data.notAddable || x.data.notAddable === 0);
      if (notAddableProducts.length > 0) {
        toast(
          //TODO: Is this await doing anything useful?
          <ToastConfirmModal onConfirm={async () => await placeOrder(orderId)} onCancel={() => setIsSubmitting(false)}>
            <div className="mx-auto">
              <p>
                Your refill order contains a medication that was shipped out to you{' '}
                {notAddableProducts[0].data.notAddable} day(s) ago. Please note that medication can not be dispensed
                until the designated interval time has elapsed.
              </p>
              {notAddableProducts.map((x) => (
                <p key={x.id}>- {x.data.product_name}</p>
              ))}
              Would you like to proceed in placing this order?
            </div>
          </ToastConfirmModal>,
          toastOptions,
        );
        //TODO: Is this await doing anything useful?
      } else await placeOrder(orderId);
    } catch (error) {
      logger.error(error);
      setIsSubmitting(false);
      toast.error(
        `Failed to place order. Please contact patient support at ${settings.support.phone.display} for assistance.`,
      );
    }
  };

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    } // holding off since submission is already in progress
    setIsSubmitting(true);
    if (selectedProducts.filter((x) => x.data.product_id !== null).length === 0) {
      toast.error('Your order basket is empty - please add a product to place an order', { toastId: 'empty-basket' });
      return setIsSubmitting(false);
    }

    try {
      const pIds = selectedProducts.map((x) => x.data.product_id);
      const orderDetails = await OrderService.getPatientExistRefill(user.id, pIds);
      if (orderDetails.data.found && orderDetails.data.order.id !== data.orderId) {
        if (!flags[FF_DISABLE_OPEN_ORDER_MODAL]) {
          toast(
            <ToastConfirmModal
              onConfirm={async () => await handleCheck(orderDetails.data.order.id)}
              onCancel={() => setIsSubmitting(false)}
            >
              <p>An Open Order Exists. Do you wish to edit it?</p>
            </ToastConfirmModal>,
            { ...toastOptions, toastId: 'edit-confirm-modal' },
          );
        } else {
          await handleCheck(orderDetails.data.order.id);
        }
      } else await handleCheck();
    } catch (error) {
      logger.error(error);
      setIsSubmitting(false);
      toast.error(
        `Failed to place order. Please contact patient support at ${settings.support.phone.display} for assistance.`,
      );
    }
  };

  const getQuantityOfProduct = (productId) => {
    const selectedProduct = selectedProducts.find((candidate) => candidate.data.product_id === productId);
    return selectedProduct?.data?.quantity || 0;
  };

  const makeNewSelectedProduct = (productId) => {
    const availableProduct = data.productList.find((x) => x.id === productId);
    const productDetails = {
      product_id: availableProduct.id,
      quantity: 1,
      ...transformAvailableProductToSelectedProductData(availableProduct),
    };
    return { id: availableProduct.id, data: productDetails };
  };

  // Called when a user wants to add another quantity of a product to cart
  const handleQtyIncreasePressed = ({ id }) => {
    const row = selectedProducts.find((x) => x.data.product_id === id);
    if (!row) {
      const newSelectedProduct = makeNewSelectedProduct(id);
      addRow(selectedProducts, newSelectedProduct);
      return;
    }
    handleQuantity('plus', row.id);
  };

  // Called when a user wants to remove a quantity of a product from cart
  const handleQtyDecreasePressed = ({ id }) => {
    handleQuantity('minus', id);
  };

  // Determines the order summary data

  const handleCheckout = async () => {
    // Don't allow users to checkout if their address contains Parcel Locker.
    if (!validateParcelLocker(user.address).isValid) {
      toast.error('Parcel Lockers are not allowed. Please change your address to a residential or PO Box address.', {
        toastId: 'parcel-locker-address',
      });
      return;
    }
    await handleSubmit();
  };

  const selectedProduct = productCardProducts.find((prod) => prod.id === selectedProductId);

  const handleRequestAlternativeProduct = () => {
    setIsEnquiryProductDialogOpen(false);
    setIsRequestConfirmationDialogOpen(true);
  };

  const handleOnSendRequest = async () => {
    setIsCreateRescriptRequestLoading(true);

    try {
      const newPatientRescriptRequest = await createPatientRescriptRequest(data.patient.id, {
        prescriptionId: selectedProduct.prescriptionId,
        productId: selectedProduct.id,
        rescriptRequestReason: selectedProduct.isGenerativeScripting ? GENERATIVE : OUT_OF_STOCK,
      });

      setIsCreateRescriptRequestLoading(false);
      setIsRequestConfirmationDialogOpen(false);
      setIsEnquiryProductDialogOpen(true);
      setProductEnquiryStatus({
        ...productEnquiryStatus,
        rescriptRequestDate: moment(newPatientRescriptRequest.created_date).format('DD/MM/YYYY'),
      });
    } catch {
      setIsCreateRescriptRequestLoading(false);
      setHasEnquiryError(true);
    }
  };

  return (
    <MontuBrandWrapper header="Refill My Medication">
      <SinglePageContentCard displayType={BRAZE_CONTENT_CARD.PATIENT_REFILL} />
      <div className="patient-refill-wrapper row" data-dd-privacy="allow">
        <div className="col-md-12 pl-0 pr-0">
          <div className="mt-3">
            <ResponsiveTwoColContainer>
              {!isDataFetched ? (
                <>
                  <ProductsColumn>
                    {Array.from({ length: 5 }, (_, index) => (
                      <ProductCardSkeleton key={index} />
                    ))}
                  </ProductsColumn>
                  <DesktopOnly>
                    <OrderSummarySkeleton />
                  </DesktopOnly>
                </>
              ) : (
                <>
                  <ProductsColumn>
                    {productCardProducts.map((productCardProduct) => (
                      <ProductCard
                        key={productCardProduct.id}
                        product={productCardProduct}
                        actions={{ handleQtyIncreasePressed, handleQtyDecreasePressed }}
                        quantity={getQuantityOfProduct(productCardProduct.id)}
                        disabled={isProductCardsDisabled(productCardProduct)}
                        setIsCheckProductEnquiryLoading={setIsCheckProductEnquiryLoading}
                        patientId={data.patient.id}
                        prescriptionId={productCardProduct.prescriptionId}
                        setProductEnquiryStatus={setProductEnquiryStatus}
                        setSelectedProductId={setSelectedProductId}
                        setIsEnquiryProductDialogOpen={setIsEnquiryProductDialogOpen}
                        setHasEnquiryError={setHasEnquiryError}
                      />
                    ))}
                  </ProductsColumn>
                  <DesktopOnly>
                    <OrderSummary
                      checkoutData={checkoutData}
                      handleCheckout={handleCheckout}
                      disabled={isCheckoutDisabled}
                      shopifyCheckoutEnabled={flags[FF_ENABLE_SHOPIFY_CHECKOUT]}
                    />
                  </DesktopOnly>
                </>
              )}

              {hasSelectedAnyProducts && (
                <MobileFixedToBottom>
                  <RefillCheckoutButton
                    totalNumberOfProducts={checkoutData.totalNumberOfProducts}
                    handleCheckout={handleCheckout}
                    amount={numberFormat(checkoutData.totalPrice)}
                    disabled={isCheckoutDisabled}
                  />
                </MobileFixedToBottom>
              )}
            </ResponsiveTwoColContainer>
          </div>
        </div>
        <EnquiryLoadingDialog
          open={isCheckProductEnquiryLoading}
          onClose={() => setIsCheckProductEnquiryLoading(false)}
        />
        <EnquiryErrorDialog open={hasEnquiryError} onClose={() => setHasEnquiryError(false)} />
        {selectedProductId && productEnquiryStatus && (
          <EnquiryProductDialog
            open={isEnquiryProductDialogOpen}
            onClose={() => {
              setIsEnquiryProductDialogOpen(false);
            }}
            product={selectedProduct}
            productEnquiryStatus={productEnquiryStatus}
            handleRequestAlternativeProduct={handleRequestAlternativeProduct}
            handleBookingSuccess={() =>
              setProductEnquiryStatus({ ...productEnquiryStatus, hasScheduledConsultation: true })
            }
          />
        )}
        <RequestConfirmationDialog
          open={isRequestConfirmationDialogOpen}
          onClose={() => setIsRequestConfirmationDialogOpen(false)}
          onSendRequest={handleOnSendRequest}
          loading={isCreateRescriptRequestLoading}
        />
      </div>
      <BrainfishHelpDeskPopup />
    </MontuBrandWrapper>
  );
}

13;

export default PatientRefill;
