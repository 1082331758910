import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { useErrorManagement } from '@/context/ErrorManagement';
import { PharmacistOrdersContext } from '@/context/pharmacist/orders/PharmacistOrders';
import {
  confirmDispenseOrder,
  getCountData,
  getUnableToDispenseInfo,
  unableToDispense,
} from '@/services/pharmacist.service';
import { PharmacistOrder, UnableToDispenseInfo, UnableToDispenseProduct } from '@/types';
import { Logger } from '@/utils/logger';

type ApprovalDocumentsModalState = {
  visible: boolean;
  order: PharmacistOrder | null;
};

type UnableToDispenseModalState = {
  visible: boolean;
  data: UnableToDispenseInfo | null;
};

type ConfirmDispenseModalState = {
  visible: boolean;
  data: number;
};

type SplitOrderModalState = {
  visible: boolean;
  order: PharmacistOrder | null;
};

type SaveUnableToDispenseResult = {
  success: boolean;
  message?: string;
};

const logger = new Logger('PharmacistOrdersModalsContext');

export type PharmacistOrdersModalsData = {
  approvalDocumentsModal: ApprovalDocumentsModalState;
  setApprovalDocumentsModal: (approvalDocumentsModal: ApprovalDocumentsModalState) => void;
  unableToDispenseModal: UnableToDispenseModalState;
  setUnableToDispenseModal: (unableToDispenseModal: UnableToDispenseModalState) => void;
  confirmDispenseModal: ConfirmDispenseModalState;
  setConfirmDispenseModal: (confirmDispenseModal: ConfirmDispenseModalState) => void;
  splitOrderModal: SplitOrderModalState;
  setSplitOrderModal: (splitOrderModal: SplitOrderModalState) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noOp = () => {};

export const PharmacistOrdersModalContext = createContext<PharmacistOrdersModalsData>({
  approvalDocumentsModal: { visible: false, order: null },
  setApprovalDocumentsModal: noOp,
  unableToDispenseModal: { visible: false, data: null },
  setUnableToDispenseModal: noOp,
  confirmDispenseModal: { visible: false, data: 0 },
  setConfirmDispenseModal: noOp,
  splitOrderModal: { visible: false, order: null },
  setSplitOrderModal: noOp,
});

export function PharmacistOrdersModalsProvider(props: PropsWithChildren) {
  const { children } = props;
  const [approvalDocumentsModal, setApprovalDocumentsModal] = useState<ApprovalDocumentsModalState>({
    visible: false,
    order: null,
  });
  const [unableToDispenseModal, setUnableToDispenseModal] = useState<UnableToDispenseModalState>({
    visible: false,
    data: null,
  });
  const [confirmDispenseModal, setConfirmDispenseModal] = useState<ConfirmDispenseModalState>({
    visible: false,
    data: 0,
  });
  const [splitOrderModal, setSplitOrderModal] = useState<SplitOrderModalState>({ visible: false, order: null });

  const value = useMemo(
    () => ({
      approvalDocumentsModal,
      unableToDispenseModal,
      confirmDispenseModal,
      splitOrderModal,
      setApprovalDocumentsModal,
      setUnableToDispenseModal,
      setConfirmDispenseModal,
      setSplitOrderModal,
    }),
    [approvalDocumentsModal, unableToDispenseModal, confirmDispenseModal, splitOrderModal],
  );

  return <PharmacistOrdersModalContext.Provider value={value}>{children}</PharmacistOrdersModalContext.Provider>;
}

export const usePharmacistApprovalDocumentsModal = () => {
  const { approvalDocumentsModal, setApprovalDocumentsModal } = useContext(PharmacistOrdersModalContext);

  const showApprovalDocumentsModal = (order: PharmacistOrder) => {
    setApprovalDocumentsModal({
      visible: true,
      order,
    });
  };

  const hideApprovalDocumentsModal = () => {
    setApprovalDocumentsModal({
      visible: false,
      order: null,
    });
  };

  return {
    approvalDocumentsModal,
    showApprovalDocumentsModal,
    hideApprovalDocumentsModal,
  };
};

export const usePharmacistConfirmDispenseModal = () => {
  const { confirmDispenseModal, setConfirmDispenseModal } = useContext(PharmacistOrdersModalContext);
  const { refetch } = useContext(PharmacistOrdersContext);
  const { enqueueError } = useErrorManagement({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showConfirmDispenseModal = async () => {
    const { data } = await getCountData();
    if (data === 0) {
      toast.info('No orders in Processing Dispense');
    } else {
      setConfirmDispenseModal({ visible: true, data });
    }
  };

  const hideConfirmDispenseModal = () => {
    setConfirmDispenseModal({
      visible: false,
      data: confirmDispenseModal.data,
    });
  };

  const confirmDispense = async () => {
    try {
      setIsSubmitting(true);
      const {
        data: { data },
      } = await confirmDispenseOrder();
      if (data) {
        const hasErrors = data?.some((orderResult) => !orderResult.success);
        if (hasErrors) {
          enqueueError({
            persist: true,
            title: 'Some of your orders did not go through',
            body: (
              <>
                Expect an email with the failed Order IDs and please get in touch with our support team at{' '}
                <b>pharmacy-ops@montu.com.au</b> for a quick resolution.
              </>
            ),
          });
        } else {
          toast.success(`${confirmDispenseModal.data} orders have been dispensed`);
          refetch();
        }
      }
    } catch (error: any) {
      toast.error(`${confirmDispenseModal.data} orders have failed to be dispensed`);
      logger.error(`${confirmDispenseModal.data} orders have failed to be dispensed`, error);
    } finally {
      hideConfirmDispenseModal();
      setIsSubmitting(false);
    }
  };

  return { confirmDispense, confirmDispenseModal, showConfirmDispenseModal, hideConfirmDispenseModal, isSubmitting };
};

export const usePharmacistUnableToDispenseModal = () => {
  const { unableToDispenseModal, setUnableToDispenseModal } = useContext(PharmacistOrdersModalContext);

  const showUnableToDispenseModal = async (orderId: number) => {
    try {
      const data = await getUnableToDispenseInfo(orderId);
      setUnableToDispenseModal({
        visible: true,
        data,
      });
    } catch (error: any) {
      logger.error(error.message || 'Failed to retrieve information');
      toast.error(error.message || 'Failed to retrieve information');
    }
  };

  const hideUnableToDispenseModal = () => {
    setUnableToDispenseModal({
      visible: false,
      data: null,
    });
  };

  return { showUnableToDispenseModal, unableToDispenseModal, hideUnableToDispenseModal };
};

export const usePharmacistSplitOrderModal = () => {
  const { splitOrderModal, setSplitOrderModal } = useContext(PharmacistOrdersModalContext);
  const { unableToDispenseReasons, refetch } = useContext(PharmacistOrdersContext);

  const showSplitOrderModal = (order: PharmacistOrder) => {
    setSplitOrderModal({
      visible: true,
      order,
    });
  };

  const hideSplitOrderModal = () => {
    setSplitOrderModal({
      visible: false,
      order: null,
    });
  };

  const saveUnableToDispense = async (
    productsWithReasons: UnableToDispenseProduct[],
  ): Promise<SaveUnableToDispenseResult> => {
    if (productsWithReasons.some(({ reason, otherReason }) => reason?.label === 'Others' && !otherReason)) {
      return {
        success: false,
        message: 'Please provide the issue',
      };
    }
    try {
      const response = await unableToDispense(productsWithReasons);
      if (response.status === 200) {
        refetch();
      }
    } catch (error: any) {
      return {
        success: false,
        message: error?.message || 'An error occurred while updating the order',
      };
    }
    return { success: true };
  };

  return { showSplitOrderModal, unableToDispenseReasons, splitOrderModal, hideSplitOrderModal, saveUnableToDispense };
};
