import { DateTime } from 'luxon';

import { Allocation, AllocationsStat, Pharmacy } from '@/pages/order-allocation-tool/orderAllocationTool.types';
import { AssignOrderFailure } from '@/services/support.service';

// Same day shipping cutoff and order times relative to this TZ
const SHIPPING_TZ = 'Australia/Melbourne';

export const getDateTimeWithShippingTz = (date?: Date): DateTime =>
  date ? DateTime.fromJSDate(date).setZone(SHIPPING_TZ) : DateTime.now().setZone(SHIPPING_TZ);

export function groupAllocationsByPharmacyId<T extends { pharmacyId: number }>(
  allocations: T[],
  pharmacies: Pharmacy[],
) {
  const allPharmacies = pharmacies.reduce((acc, { id }) => {
    acc[id] = [];
    return acc;
  }, {} as Record<number, T[]>);

  return allocations.reduce((acc, allocation) => {
    if (!acc[allocation.pharmacyId]) {
      acc[allocation.pharmacyId] = [];
    }

    acc[allocation.pharmacyId].push(allocation);
    return acc;
  }, allPharmacies);
}

export function formatAllocationRowOutput({ pharmacyCode, orders }: AllocationsStat) {
  return `Pharmacy Code: ${pharmacyCode}\nOrder Codes: ${orders.map(({ orderCode }) => orderCode).join(', ')}`;
}

export function formatFailedOrderAllocation({ orderStatusId, reason }: AssignOrderFailure) {
  const suffix = orderStatusId ? ` (${orderStatusId})` : '';
  return `${reason}${suffix}`;
}

export function formatSameDayDeliveryRange(start: Date, end: Date) {
  const dateFormat = 'cccc, LLLL d t';
  return `${DateTime.fromJSDate(start).toFormat(dateFormat)}  - ${DateTime.fromJSDate(end).toFormat(
    dateFormat,
  )}. ${SHIPPING_TZ} time`;
}

export function averageDistanceInKmExcludingOrdersWithoutCoordinates(allocations: Allocation[]) {
  const allocationsWithDistance = allocations.filter((a) => Boolean(a.distanceFromDeliveryAddressInMeters));

  const totalDistance = allocationsWithDistance.reduce(
    (acc, { distanceFromDeliveryAddressInMeters }) => acc + (distanceFromDeliveryAddressInMeters || 0),
    0,
  );

  return Math.round(totalDistance / allocationsWithDistance.length / 1000);
}

export function getInterstateOrderData(allocations: Allocation[]) {
  // im sorry this is a little confusing. Our linter doesnt like loops.
  const result = allocations.reduce(
    (progress, allocation) => {
      const interstateOrdersCount =
        allocation.pharmacyState === allocation.orderState
          ? progress.interstateOrdersCount
          : progress.interstateOrdersCount + 1;

      const fromStates = progress.fromStates.includes(allocation.pharmacyState)
        ? progress.fromStates
        : [...progress.fromStates, allocation.pharmacyState];

      const toStates = progress.toStates.includes(allocation.orderState)
        ? progress.toStates
        : [...progress.toStates, allocation.orderState];

      const currentFromState = progress.fromToMap[allocation.pharmacyState] || {};
      const currentCount = currentFromState[allocation.orderState] || 0;

      const fromToMap = {
        ...progress.fromToMap,
        [allocation.pharmacyState]: {
          ...currentFromState,
          [allocation.orderState]: currentCount + 1,
        },
      };

      return {
        interstateOrdersCount,
        fromStates,
        toStates,
        fromToMap,
      };
    },
    {
      interstateOrdersCount: 0,
      fromStates: [],
      toStates: [],
      fromToMap: {},
    } as {
      interstateOrdersCount: number;
      fromStates: string[];
      toStates: string[];
      fromToMap: Record<string, Record<string, number>>;
    },
  );

  return {
    interstateOrderPecentage: Math.round((result.interstateOrdersCount / allocations.length) * 100),
    ...result,
  };
}
