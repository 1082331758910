import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Menu, MenuItem, styled, Typography } from '@mui/material';

import USER_ROLES from '@/constants/userRoles';

interface AdminMenuV2Props {
  roleId: number;
  firstName: string;
  gpCode: string;
}

const Container = styled(Box)(() => ({
  marginRight: '8rem',
}));

const AvatarBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  marginLeft: theme.spacing(4),
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2),
}));

const GpCode = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: theme.spacing(2),
}));

function AdminMenuV2({ roleId, firstName, gpCode }: AdminMenuV2Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPasswordLink = () => {
    switch (roleId) {
      case USER_ROLES.patient:
        return '/password/patient';
      case USER_ROLES.doctor:
        return '/password/doctor';
      default:
        return '/password/admin';
    }
  };

  return (
    <Container>
      <AvatarBox onClick={handleClick}>
        <CustomAvatar>
          <PersonIcon sx={{ color: 'secondary.main', fontSize: 25 }} />
        </CustomAvatar>
        <Name variant="body1">{firstName}</Name>
      </AvatarBox>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} disableScrollLock>
        <MenuItem component={RouterLink} to="/profile-detail">
          Profile
        </MenuItem>
        <MenuItem component={RouterLink} to={getPasswordLink()}>
          Password
        </MenuItem>
        <MenuItem component={RouterLink} to="/logout">
          Logout
        </MenuItem>
      </Menu>

      {roleId === USER_ROLES.doctor && <GpCode variant="body2">{gpCode}</GpCode>}
    </Container>
  );
}

export default AdminMenuV2;
