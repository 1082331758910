import { useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { getPharmacistDetails } from '@/services/pharmacist.service';
import {
  PharmacyInventoryHistoryParams,
  PharmacyInventoryHistoryResponse,
  PharmacyService,
} from '@/services/pharmacy.service';

const useInventoryHistory = (
  paginationModel: { page: number; pageSize: number },
  sortingOrder: { name: string; reverse: boolean },
  search: string,
) =>
  useQuery<PharmacyInventoryHistoryResponse, Error>(
    ['inventoryHistory', paginationModel, sortingOrder, search],
    async () => {
      const params: PharmacyInventoryHistoryParams = {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: sortingOrder.name,
        sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
        filter: search,
      };

      const { data: pharmacistDetails } = await getPharmacistDetails();
      const currentPharmacyId = pharmacistDetails.pharmacy_id;

      const response = await PharmacyService.getInventoryHistory(currentPharmacyId, params);

      if (!response?.data?.status) {
        throw new Error(response?.data?.message || 'Error fetching inventory history');
      }

      return response?.data;
    },
    {
      onError: (error) => {
        toast.error(error.message);
      },
      staleTime: 5000,
      keepPreviousData: true,
    },
  );

export default useInventoryHistory;
