import { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginationModel, PaginationVariant, Table } from '@montugroup/design-system';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Column, Row, SortingState } from '@tanstack/react-table';
import debounce from 'awesome-debounce-promise';

import TableSearchInput from '@/components/common/TableSearchInput';
import { getPharmacistDetails } from '@/services/pharmacist.service';
import { PharmacyProductInventory } from '@/types/pharmacy.types';

import useGetInventoryProducts from '../../../../hooks/pharmacist/useGetInventoryProducts';

import InventoryAdjustment from './InventoryAdjustment';

const DEFAULT_PAGE_SIZE = 25;
const INITIAL_PAGE = 0;

function ProductOverview() {
  const [search, setSearch] = useState('');
  const [showAdjustmentModal, setShowAdjustmentModal] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>({
    page: INITIAL_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [sortingOrder, setSortingOrder] = useState({ name: 'name', reverse: false });
  const [pharmacyId, setPharmacyId] = useState<null | number>(0);

  useEffect(() => {
    const fetchPharmacistDetails = async () => {
      const { data: pharmacistDetails } = await getPharmacistDetails();
      setPharmacyId(pharmacistDetails.pharmacy_id);
    };
    fetchPharmacistDetails();
  }, []);

  const { products, isLoading, refetch } = useGetInventoryProducts(pharmacyId);

  const handlePaginationModelChange = ({ page, pageSize }: PaginationModel) => {
    setPaginationModel({ page, pageSize });
  };

  const handleSearch = debounce((value: string) => {
    setSearch(value);
    setPaginationModel((prev) => ({ ...prev, page: INITIAL_PAGE }));
  }, 250);

  const updateSort = useCallback(
    (state: SortingState) => {
      if (state.length > 0) {
        const { id, desc } = state[0];
        if (sortingOrder.name !== id || sortingOrder.reverse !== desc) {
          setSortingOrder({ name: id, reverse: desc });
        }
      }
    },
    [sortingOrder],
  );

  const { filteredProducts, total } = useMemo(() => {
    if (!products) return { filteredProducts: [], total: 0 };

    const lowercasedSearch = search.toLowerCase();
    const searchedProducts = products.filter((product: PharmacyProductInventory) =>
      product.name.toLowerCase().includes(lowercasedSearch),
    );

    const sortedProducts = [...searchedProducts].sort((a, b) => {
      const aValue = a[sortingOrder.name] as string | number;
      const bValue = b[sortingOrder.name] as string | number;

      if (aValue < bValue) return sortingOrder.reverse ? 1 : -1;
      if (aValue > bValue) return sortingOrder.reverse ? -1 : 1;
      return 0;
    });

    const paginatedProducts = sortedProducts.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize,
    );

    return {
      filteredProducts: paginatedProducts,
      total: searchedProducts.length,
    };
  }, [products, search, sortingOrder, paginationModel]);

  const renderSimpleValueTableCell = ({
    row,
    column,
  }: {
    row: Row<PharmacyProductInventory>;
    column: Column<PharmacyProductInventory>;
  }) => <Box>{row.getValue(column.id)}</Box>;

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Product Name',
        enableSorting: true,
        cell: renderSimpleValueTableCell,
      },
      {
        accessorKey: 'stock',
        header: 'Stock on Hand',
        enableSorting: false,
        cell: renderSimpleValueTableCell,
      },
      {
        accessorKey: 'allocatedStock',
        header: 'Allocated Stock',
        enableSorting: false,
        cell: renderSimpleValueTableCell,
      },
      {
        accessorKey: 'availableStock',
        header: 'Available Stock',
        enableSorting: false,
        cell: renderSimpleValueTableCell,
      },
    ],
    [renderSimpleValueTableCell],
  );

  return (
    <Box>
      <Typography variant="h5" component="h2" paddingTop={5} paddingBottom={6}>
        Product Overview
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={(theme) => theme.spacing(4)}
        justifyContent="space-between"
        paddingBottom={6}
      >
        <TableSearchInput value={search} onChange={handleSearch} />
        <Button variant="contained" size="large" onClick={() => setShowAdjustmentModal(true)}>
          Add New Adjustment
        </Button>
      </Stack>
      <Table
        data={filteredProducts}
        columns={columns}
        showPagination
        manualPagination
        pageSize={paginationModel.pageSize}
        total={total}
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        onPaginationModelChange={handlePaginationModelChange}
        onSortingChange={updateSort}
        isLoading={isLoading}
      />
      {showAdjustmentModal && (
        <InventoryAdjustment
          handleClose={() => setShowAdjustmentModal(false)}
          refetchProducts={refetch}
          products={products}
          pharmacyId={pharmacyId?.toString() || ''}
          showAdjustmentModal={showAdjustmentModal}
        />
      )}
    </Box>
  );
}

export default ProductOverview;
