//! DO NOT EDIT THIS FILE IT IS GENERATED USING:
// yarn generate-flags

// Default On Variation: true
// Default Off Variation: false
export const FF_REFUND_NON_REFUND_CREDIT = 'refund_non_refund_credit';

// Description: As a Pharmacist, I would like to see the inventory count of products assigned to orders and Available stock in Pharmacy Access.
// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACY_STOCK_ENHANCEMENT = 'pharmacy_stock_enhancement';

// Description: Feature toggle for the new Dispense flow introduced in MC-3844 and MC-3589
// Default On Variation: true
// Default Off Variation: false
export const FF_PROCESSING_DISPENSE_FLOW = 'processing_dispense_flow';

// Description: Feature flag for Unable to dispense workflow https://montugroup.atlassian.net/browse/MC-3798
// Default On Variation: true
// Default Off Variation: false
export const FF_UNABLE_TO_DISPENSE = 'unable_to_dispense';

// Description: When this flag is on the banner will be displayed in payment page indicating that there can be intermittent payment issues.
// Default On Variation: Please note possible payment outage on April 22 between 2-5am AEST due to maintenance. Thank you for your patience.
// Default Off Variation: OFF
export const FF_SHOW_PAYMENT_WARNING = 'show_payment_warning';

// Description: This flag enables the 3 new toggles we introduce on montu admin when editing a product (Supply chain risk-Product exclusion from new prescriptions-generative scripting)
// Default On Variation: true
// Default Off Variation: false
export const FF_GENERATIVE_SCRIPTING_TOGGLES = 'generative_scripting_toggles';

// Default On Variation: 3
// Default Off Variation: 13.64
export const FF_SHIPPING_PRICE = 'shipping_price';

// Default On Variation: true
// Default Off Variation: false
export const FF_ORDER_FORM_DISABLE_EDIT_IF_PAID = 'order_form_disable_edit_if_paid';

// Description: Flag to enable the new endpoint that fixes a bug with discounts
// Default On Variation: true
// Default Off Variation: false
export const FF_USE_NEW_EDIT_ORDER_ENDPOINT = 'use_new_edit_order_endpoint';

// Description: Allows us to quickly disable and re-enable the booking buttons for consultations
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_CONSULTATION_BUTTONS = 'disable_consultation_buttons';

// Description: Part of B2C-1813 - Flag for the customer to enquire about alternative for OOS products through the system without the need for a call
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PRODUCT_ISSUE_ENQUIRIES = 'enable_product_issue_enquiries';

// Description: A flag used to toggle features used in the ERX Paper Script Solution. Also see ERX Token Generation Solution for related flags
// Default On Variation: true
// Default Off Variation: false
export const FF_ERX_PAPER_SCRIPT_SOLUTION = 'erx_paper_script_solution';

// Description: Flag to disable Product Edits in Circuit, getting ready for using Product Edits from Shopify https://montugroup.atlassian.net/browse/B2CS-87
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_DISABLE_PRODUCT_EDIT = 'shopify_disable_product_edit';

// Description: https://montugroup.atlassian.net/browse/B2CS-98
// Default On Variation: true
// Default Off Variation: false
export const FF_USE_SHOPIFY_PRODUCT_IMAGES = 'use_shopify_product_images';

// Description: When enabled all users are shown the maintenance screen
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOW_MAINTENANCE_SCREEN = 'show_maintenance_screen';

// Description: When this flag is enabled patient will be presented with Shopify checkout page instead of Circuit bespoke checkout page
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SHOPIFY_CHECKOUT = 'enable_shopify_checkout';

// Description: when enabled allows pharmacist to filter orders on /pharmacist/orders by order status
// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACY_FILTER_ORDERS_BY_STATUS = 'pharmacy_filter_orders_by_status';

// Description: Leverage the new capability of multiple product images to show carousels on the product catalogue pages
// Default On Variation: true
// Default Off Variation: false
export const FF_PRODUCT_IMAGE_CAROUSEL = 'product_image_carousel';

// Description: Updated patient order statuses to reduce confusion. This feature flag only impacts what patients see in the patient portal, it does not impact the granular statuses that the Montu team sees. --> https://montugroup.atlassian.net/browse/FX-266
// Default On Variation: true
// Default Off Variation: false
export const FF_UPDATED_PATIENT_ORDER_STATUSES = 'UPDATED_PATIENT_ORDER_STATUSES';

// Description: This is to allow us to toggle the visibility of the word of mouth campaign that allows us to generate referral codes
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_WOM_CAMPAIGN = 'enable_wom_campaign';

// Description: When enabled, patients who have made orders in the past two weeks that have been shipped by pharmacies will see this component
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_TRACKING_STATUS_NOTIFICATION = 'enable_order_tracking_status_notification';

// Description: Enables or disables brainfish help widget
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_BRAINFISH = 'enable_brainfish';

// Description: Disables the 'An Open Order Exists. Do you wish to edit it?' modal that shows when attempting to check out with an existing pending order.
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_OPEN_ORDER_MODAL = 'disable_open_order_modal';

// Description: Enable super admins to adjust inventory.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SUPER_ADMIN_INVENTORY = 'enable_super_admin_inventory';

// Description: enables patient profile merges for super admins
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_PROFILE_MERGE = 'enable_patient_profile_merge';

// Description: Toggles the visibility of the order assignment tool until ready for production use
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_ASSIGNMENT_TOOL = 'enable_order_assignment_tool';

// Description: https://montugroup.atlassian.net/wiki/spaces/Eng/pages/400654438/Project%3A+uMeds
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_UMEDS_REBRAND = 'enable_umeds_rebrand';

// Description: Display a link to support article when patient login fails
// Default On Variation: true
// Default Off Variation: false
export const FF_LOGIN_FAILURE_SUPPORT_LINK = 'login_failure_support_link';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SHOPIFY_DISCOUNTS = 'enable_shopify_discounts';

// Description: When enabled, FE uses a new BE endpoint which returns products and inventory for a pharmacy including inactive products instead of only active products.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS = 'enable_pharmacy_inventory_inactive_products';

// Description: This feature flag enables or disables the display of a notice message on the modal. When enabled, the content of the Word of Mouth (WOM) popup is replaced with a notice message.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_NOTICE_ON_MODAL = 'enable_notice_on_modal';

// Description: FF to control rollout of google places autocomplete for Circuit
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE = 'enable_google_places_autocomplete';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE_PT_PROFILE = 'enable_google_places_autocomplete_pt_profile';

// Description: The FF will enable/disable features related to replacement orders functionality in FE.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_REPLACEMENT_ORDERS_IN_SHOPIFY = 'enable_replacement_orders_in_shopify';

// Description: A flag to toggle banner in circuit frontend.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SAFESCRIPT_OUTAGE_BANNER = 'enable_safescript_outage_banner';

// Description: This feature flag enables the client to filter out inactive pharmacies when searching through trusted partner pharmacies.
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_INACTIVE_PHARMACY_FILTER = 'enable_inactive_pharmacy_filter';

// Description: Show product description and usage sections for my treatment plan
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE = 'show_product_description_and_usage';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_FILTER_BY_PHARMACY = 'enable_order_filter_by_pharmacy';

// Description: Hide Add Credit button
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_ADMIN_CREDIT_PROVISION = 'disable_admin_credit_provision';

// Description: Shows credit deprecation warnings banner and text UI changes
// Default On Variation: true
// Default Off Variation: false
export const FF_PATIENT_CREDIT_DEPRECATION_WARNINGS = 'patient_credit_deprecation_warnings';

// Description: Is the link url string used on the credit page banner
// Default On Variation: https://support.montu.com.au/hc/en-au/articles/34907935744153-How-are-credits-and-discounts-changing
// Default Off Variation: https://support.montu.com.au/hc/en-au/articles/34907935744153-How-are-credits-and-discounts-changing
export const FF_CREDIT_CHANGES_BANNER_LINK = 'credit_changes_banner_link';

// Description: Replacing product catalog with only prescribed product information for compliance. https://montugroup.atlassian.net/browse/UMED-69
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_MY_TREATMENT_PLAN = 'enable_my_treatment_plan';

// Description: Show the patient refill banner regarding Shopify checkout
// Default On Variation: true
// Default Off Variation: false
export const FF_PATIENT_REFILL_CHECKOUT_BANNER = 'patient_refill_checkout_banner';

// Description: link url string used on the patient refill page banner regarding checkout changes
// Default On Variation: https://support.montu.com.au/hc/en-au/articles/34912569221657-How-has-the-checkout-process-changed
// Default Off Variation: https://support.montu.com.au/hc/en-au/articles/34912569221657-How-has-the-checkout-process-changed
export const FF_REFILL_CHECKOUT_CHANGES_BANNER_LINK = 'refill_checkout_changes_banner_link';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ORDER_PHARMACY_ALLOCATION = 'enable_order_pharmacy_allocation';

// Description: Send query params to shopify discount app via circuit modal
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_DISCOUNT_MODAL_QUERY_PARAMS = 'shopify_discount_modal_query_params';

// Description: When enabled, admin discount creation button is hidden
// Default On Variation: true
// Default Off Variation: false
export const FF_DISABLE_ADMIN_DISCOUNT_CREATION = 'disable_admin_discount_creation';

// Description: enables the home page redesign for patients
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_HOME_PAGE = 'enable_home_page';

// Description: enables the profile page redesign for patients
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PROFILE_PAGE = 'enable_profile_page';

// Description: enables the consultation page redesign for patients
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_CONSULTATION_PAGE = 'enable_consultation_page';

// Description: enables the prescription page redesign for patients
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PRESCRIPTION_PAGE = 'enable_prescription_page';

// Description: enables the help page redesign for patients
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_HELP_PAGE = 'enable_help_page';

// Default On Variation: false
// Default Off Variation: false
export const FF_ORDERMEDICATIONPAGE2024 = 'OrderMedicationPage2024';

// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_DISCOUNT_SUPER_ADMINS = 'shopify_discount_super_admins';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_404_AND_500_ERROR_PAGES = 'enable_404_and_500_error_pages';

// Description: Enables the patients orders page redesign for patients
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_ORDERS_PAGE = 'enable_patient_orders_page';

// Description: Enables the patients orders white label images
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES = 'enable_patient_orders_white_label_images';

// Description: This is a feature flag for the auto complete address on the new Profile Page. It is separate to other autocomplete feature flags as it is using a new component being built in the design system
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_GOOGLE_PLACES_UMEDS = 'enable_google_places_umeds';

// Description: Redirect to new shopify thank you order summary.
// Default On Variation: true
// Default Off Variation: false
export const FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE = 'shopify_redirect_thank_you_page';

// Description: Enables Snowplow Clickstream tracking in Circuit
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_SNOWPLOW_CIRCUIT = 'enable_snowplow_circuit';

// Description: Enable's the new TS version of the Supplies page
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_TS_SUPPLIERS_PAGE = 'enable_ts_suppliers_page';

// Description: https://montugroup.atlassian.net/browse/B2CS-1235
// Default On Variation: true
// Default Off Variation: false
export const FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT = 'patient_discharge_circuit_checkout_disabled_alert';

// Description: Enable umeds checkbox on Pharmacy details page
// Default On Variation: true
// Default Off Variation: false
export const FF_FF_ENABLE_UMEDS_CHECKBOX = 'FF_ENABLE_UMEDS_CHECKBOX';

// Description: The new umeds rebrand wants to keep montu brand as the partner for the initial phases.
// Default On Variation: true
// Default Off Variation: false
export const FF_POWERED_BY_UMEDS = 'powered_by_umeds';

// Description: patient_refill_checkout_banner text
// Default On Variation: Please note: vaporiser devices (that are not ARTG listed) and batteries are now no longer available due to a change in legislation. If impacted, book a follow-up appointment with your doctor to discuss alternatives.
// Default Off Variation: Please note: vaporiser devices (that are not ARTG listed) and batteries are now no longer available due to a change in legislation. If impacted, book a follow-up appointment with your doctor to discuss alternatives.
export const FF_PATIENT_REFILL_CHECKOUT_BANNER_TEXT = 'patient_refill_checkout_banner_text';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACIST_ORDERS_TS_MIGRATION = 'enable_pharmacist_orders_ts_migration';

// Description: for pharmacist login page
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PHARMACIST_LOGIN_PAGE = 'enable_pharmacist_login_page';

// Description: Browsing of product catalog by pharmacists
// Default On Variation: true
// Default Off Variation: false
export const FF_PHARMACIST_PRODUCT_CATALOG = 'pharmacist_product_catalog';

// Description: Word of mouth related price control
// Default On Variation: 35
// Default Off Variation: 20
export const FF_WOM_PRICE = 'wom_price';

// Description: Enables the brand theme navigation for uMeds/Montu
// Default On Variation: true
// Default Off Variation: false
export const FF_BRAND_THEME_NAV = 'brand_theme_nav';

// Description: Enables or disables the Zendesk help desk widget
// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_ZENDESK_WIDGET = 'enable_zendesk_widget';

// Default On Variation: true
// Default Off Variation: false
export const FF_ENABLE_PREFILL_PRODUCT_QUANTITY = 'enable_prefill_product_quantity';
