import { useEffect, useState } from 'react';
import axios from 'axios';

import settings from '@/constants/constants';
import menuData, { MenuItem } from '@/constants/menu';
import USER_ROLES from '@/constants/userRoles';
import { AuthService } from '@/services/authentication.service';

interface User {
  firstName: string;
  gpCode: string;
  roleId: number;
  patNumber: string;
  clientId: string;
}

function useUserMenu(): {
  user: User;
  menuItems: MenuItem[];
  moreMenuItems: MenuItem[];
  state: {
    isExpanded: boolean;
    active: boolean;
  };
} {
  const [user, setUser] = useState<User>({
    firstName: '',
    gpCode: '',
    roleId: 0,
    patNumber: '',
    clientId: '',
  });
  const [state, setState] = useState({
    isExpanded: false,
    active: false,
  });

  useEffect(() => {
    const currentUser = AuthService.getUser();
    if (currentUser && currentUser.user) {
      axios.get(`${settings.url}/user`).then((resp) => {
        const { data } = resp;
        setUser({
          firstName: data.first_name,
          gpCode: data.gp_code,
          roleId: data.role_id,
          patNumber: data.patNumber,
          clientId: data.client_id,
        });
      });

      axios.get(`${settings.url}/patient/patientReferral/${currentUser.user.id}`).then((response) => {
        if (response.data.patientReferralCode) {
          setState((prevState) => ({
            ...prevState,
            active: true,
          }));
        }
      });
    }
  }, []);

  const roleId = user.roleId || USER_ROLES.doctor;

  const menuItems = menuData.menus.filter((menu) => menu.roleId.includes(roleId));
  const moreMenuItems = menuData.moreMenu.filter((menu) => menu.roleId.includes(roleId));

  return { user, menuItems, moreMenuItems, state };
}

export default useUserMenu;
