import React from 'react';
import { BsFillExclamationCircleFill, BsInfoCircle } from 'react-icons/bs';
import Select from 'react-select';
import { toast } from 'react-toastify';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import Modal from 'bootstrap/js/dist/modal';
import moment from 'moment';
import FilterableTable from 'rc-data-table';
import { createBrowserHistory } from 'history';
import { Box } from '@mui/material';

import settings from '../../constants/constants';
import {
  confirmDispenseOrder,
  getCountData,
  getReasonStatusList,
  getUnableToDispenseInfo,
  processDispensing,
  unableToDispense,
} from '../../services/pharmacist.service';
import Button from '../common/button';
import CircleLoader from '../common/circleLoader';
import CustomModal from '../common/CustomModal';
import FormInput from '../common/FormInput';
import customSelectStyles from '../dropdown/customSelectStyle';
import FilterByOrderStatusSelect from '../common/FilterByOrderStatusSelect';
import UnableToDispenseModal from '../order/unableToDispenseModal';
import PharmacistApprovalDocumentsModal from './pharmacistApprovalDocumentsModal';
import './inventory.scss';
import { PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import { Logger } from '@/utils/logger';
import { OrderStatus } from '../order/order.types';
import SameDayDeliveryChip from '../order/orderAllocationTool/SameDayDeliveryChip';

const logger = new Logger('pharmacistOrder');

const { url } = settings;
const { CancelToken } = axios;
let cancel;

const tabs = {
  OPEN_ORDER_OVERVIEW: 'openOrderOverview',
  DISPENSED_ORDER_OVERVIEW: 'dispensedOverview',
  ALL_ORDER_OVERVIEW: 'allOrderOverview',
  PROCESSING_DISPENSE: 'processingDispense',
};
// Show light green row background color for orders that are prioritised.
const priorityCheck = (record) => {
  const classNames = [];

  if (record.priority) {
    classNames.push('priority-based-order');
  }

  if (record.potential_same_day_delivery) {
    classNames.push('same-day-delivery-order');
  }

  return classNames.length ? classNames.join(' ') : null;
};

const generateExcelSheet = async () => {
  await axios({
    url: 'excel/pharmacist/exportOrders',
    method: 'POST',
    responseType: 'blob',
  }).then((response) => {
    const fileTitle = `${new Date().getTime()}-pharmacy-orders`;
    const urls = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urls;
    link.setAttribute('download', `${fileTitle}.xlsx`);
    document.body.appendChild(link);
    link.click();
  });
};

const history = createBrowserHistory();

class PharmacistOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      orders: [],
      selectedOrders: [],
      selectedReasonStatus: [],
      pageSize: 50,
      count: 0,
      page: 0,
      filter: '',
      sortingOrder: {},
      activeTab: tabs.OPEN_ORDER_OVERVIEW,
      modelShow: false,
      selectedOrder: {},
      selectedAlpat: {},
      selectedProducts: [],
      selectedProductReason: {},
      orderSelectedForApprovalDocumentsModal: {},
      reasonStatus: [],
      selectedProduct: null,
      otherReason: '',
      additionalComment: '',
      unableToDispenseData: null,
      disabled: false,
      showConfirmDispenseModal: false,
      showApprovalDocumentsModal: false,
      showDispenseErrorMessage: false,
      processingDispenseCount: null,
    };
    this.priorityCheck = priorityCheck.bind(this);
    this.generateExcelSheet = generateExcelSheet.bind(this);
  }

  componentDidMount() {
    this.getOrders();
    this.getReasonNameStatusList();
  }

  /**
   * Stops all promises/state changes as component is unmounting.
   * @returns {Promise<void>}
   */
  async componentWillUnmount() {
    this.setState({}); // Changed the function related to AwesomeDebouncePromise
  }

  orderToggleTab = (activeTab) => {
    this.setState(
      {
        loading: true,
        activeTab,
        pageSize: 50,
        count: 0,
        page: 0,
        filter: '',
        sortingOrder: {},
      },
      () => {
        this.debouncedGetOrders();
      },
    );
  };

  getOrders = async () => {
    const { page, pageSize, sortingOrder, filter, activeTab } = this.state;
    if (cancel) {
      cancel();
    }
    await axios
      .get(`${url}/pharmacist/orders`, {
        cancelToken: new CancelToken((c) => {
          cancel = c;
        }),
        params: {
          limit: pageSize,
          offset: page * pageSize,
          sortingOrder: sortingOrder.name,
          sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
          filter,
          ts: new Date().getTime(),
          activeTab,
        },
      })
      .then((data) => {
        this.setState({ orders: data.data.orders, count: data.data.count, loading: false });
      });
  };

  // Returns a debounced version of the getOrders function that will only run a search
  // after there has been no user input for 500ms
  // eslint-disable-next-line react/sort-comp
  debouncedGetOrders = AwesomeDebouncePromise(this.getOrders, 500);

  showSplitOrderModal = (orderId) => {
    const { orders } = this.state;
    const { prescriptions, product_details: productDetails, quantity } = orders.find((order) => order.id === orderId);
    const prescriptionNames = prescriptions.map((element) => element.name);
    const products = productDetails.map((element, index) => ({
      name: element.name,
      productId: element.id,
      qty: quantity[index],
    }));

    this.setState({
      selectedOrder: orders.find((order) => order.id === orderId),
      selectedAlpat: prescriptionNames.join(', ') || '-',
      selectedProducts: products,
      modelShow: true,
    });
  };

  onPageChange = (page) => {
    this.setState(
      {
        page,
        loading: true,
      },
      () => {
        this.getOrders();
      },
    );
  };

  onFilter = (filter) => {
    const path = filter ? `/pharmacist/orders?search=${encodeURIComponent(filter)}` : '/pharmacist/orders';
    history.push(path);

    this.setState(
      {
        page: 0,
        filter: filter || '',
      },
      () => {
        this.debouncedGetOrders();
      },
    );
  };

  getSortColumn = (sort) => {
    this.setState(
      {
        sortingOrder: sort[0],
        loading: true,
      },
      () => {
        this.getOrders();
      },
    );
  };

  selectOrder = (e) => {
    const { selectedOrders } = this.state;
    const orderId = parseInt(e.target.value, 10);
    const index = selectedOrders.indexOf(orderId);
    if (index > -1) {
      this.setState({
        selectedOrders: [...selectedOrders.slice(0, index), ...selectedOrders.slice(index + 1)],
      });
      return;
    }
    this.setState({
      selectedOrders: [...selectedOrders, orderId],
    });
  };

  selectAllOrders = () => {
    const { selectedOrders, orders } = this.state;
    if (selectedOrders.length > 0) {
      this.setState({
        selectedOrders: [],
      });
      return;
    }
    this.setState({
      selectedOrders: orders.map((x) => x.id),
    });
  };

  dispenseOrders = async () => {
    const { selectedOrders } = this.state;
    if (selectedOrders.length <= 0) {
      toast.error('Select one or more orders to proceed');
      return;
    }
    this.setState({ loading: true });

    processDispensing(selectedOrders).then((response) => {
      if (response.data.status) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
        logger.error('dispenseOrders', response?.data?.message);
      }
      this.getOrders();
      this.setState({ selectedOrders: [], loading: false });
    });
  };

  getReasonNameStatusList = async () => {
    const reasonStatus = await getReasonStatusList();
    if (reasonStatus.data?.length > 0) {
      const status = reasonStatus.data.map((action) => ({ label: action.name, value: action.id }));
      this.setState({
        reasonStatus: status,
      });
    }
  };

  handleSelectedPharmacistStatus = (reason, product) => {
    const { selectedReasonStatus, selectedProductReason } = this.state;
    const updatedReasons = [...selectedReasonStatus];
    const index = updatedReasons.findIndex((item) => item.product === product);
    if (index >= 0) {
      updatedReasons[index].reason = reason;
    } else {
      updatedReasons.push({ product, reason });
    }
    this.setState({
      selectedReasonStatus: updatedReasons,
      selectedProduct: product,
      selectedProductReason: {
        ...selectedProductReason,
        [product.productId]: {
          reason: reason.label,
          otherReason: '',
        },
      },
    });
  };

  showOtherReason = (product) => {
    const { selectedReasonStatus } = this.state;
    const index = selectedReasonStatus.findIndex((item) => item.product === product && item.reason?.label === 'Others');
    return index >= 0;
  };

  handleOtherReasonChange = (e, product) => {
    const { selectedProductReason } = this.state;
    this.setState({
      selectedProductReason: {
        ...selectedProductReason,
        [product.productId]: {
          ...selectedProductReason[product.productId],
          otherReason: e.target.value,
        },
      },
    });
  };

  handleAdditionalCommentChange = (e) => {
    const { selectedOrder } = this.state;
    this.setState({
      selectedOrder: {
        ...selectedOrder,
        additionalComment: e.target.value,
      },
    });
  };

  hideSplitOrderModal = () => {
    this.setState({
      modelShow: false,
    });
  };

  saveUnableToDispense = async () => {
    this.setState({ disabled: true });
    try {
      const { selectedReasonStatus, selectedProducts, selectedOrder, selectedProductReason } = this.state;
      const productsWithReasons = selectedProducts.map((product) => {
        const reasonObj = selectedReasonStatus.find((item) => item.product === product);
        const reason = reasonObj?.reason ?? null;
        const { orderId } = selectedOrder;
        const { productId } = product;
        const otherReason = selectedProductReason[product.productId]?.otherReason ?? '';
        const additionalComment = selectedOrder.additionalComment ?? '';
        return { reason, productId, orderId, otherReason, additionalComment };
      });
      if (productsWithReasons.some(({ reason, otherReason }) => reason?.label === 'Others' && !otherReason)) {
        toast.error('Please provide the issue');
        this.setState({
          disabled: false,
        });
      } else {
        const response = await unableToDispense(productsWithReasons);
        if (response.status === 200) {
          toast.success('Order Updated');
          this.setState(
            {
              selectedProducts: [],
              selectedReasonStatus: [],
              selectedProductReason: {},
              disabled: false,
              loading: true,
            },
            () => {
              this.getOrders();
            },
          );
        } else {
          toast.error('Order not Updated');
          logger.error('saveUnableToDispense Order not Updated');

          this.setState({
            disabled: false,
          });
        }
        this.hideSplitOrderModal();
      }
    } catch (error) {
      toast.error('An error occurred while updating the order');
      logger.error('An error occurred while updating the order', error.message);
      this.setState({
        disabled: false,
      });
    }
  };

  getUnableToDispenseInfo = async (orderId) => {
    await getUnableToDispenseInfo(orderId)
      .then((unableToDispenseData) => {
        this.setState(
          {
            unableToDispenseData,
          },
          () => {
            this.showUnableToDispenseModal();
          },
        );
      })
      .catch((error) => {
        logger.error('getUnableToDispenseInfo', error?.message);
        toast.error(error.message);
      });
  };

  showUnableToDispenseModal = () => {
    const element = document.getElementById('UnableToDispenseModal');
    if (!element) return;
    this.UnableToDispenseModal = new Modal(element, {
      backdrop: false,
      keyboard: false,
    });
    this.UnableToDispenseModal.show();
  };

  hideUnableToDispenseModal = () => {
    this.setState({
      unableToDispenseData: null,
    });
    this.UnableToDispenseModal.hide();
  };

  openConfirmDispenseModal = async () => {
    await getCountData()
      .then((response) => {
        if (response.data === 0) {
          toast.info('No orders in Processing Dispense');
        } else {
          this.setState({
            showConfirmDispenseModal: true,
            processingDispenseCount: response.data,
          });
        }
      })
      .catch((err) => {
        toast.error('Failed to fetch Processing dispense orders count');
        logger.error('Failed to fetch Processing dispense orders count', err.message);
      });
  };

  closeConfirmDispenseModal = () => {
    this.setState({
      showConfirmDispenseModal: false,
      loading: false,
    });
  };

  confirmDispense = async () => {
    const { processingDispenseCount } = this.state;
    this.setState({ loading: true });
    await confirmDispenseOrder()
      .then((response) => {
        const responseData = response.data?.data;
        if (responseData?.length) {
          const errors = responseData.filter((orderResult) => !orderResult.success);
          if (errors.length > 0) {
            this.showDispenseErrorMessage();
          } else {
            toast.success(`${processingDispenseCount} Orders have been Dispensed`);
          }
        } else {
          toast.success(`${processingDispenseCount} Orders have been Dispensed`);
        }

        this.getOrders();
        this.closeConfirmDispenseModal();
      })
      .catch((err) => {
        toast.error(`${processingDispenseCount} Orders have failed to be Dispensed`);
        logger.error(`${processingDispenseCount} Orders have failed to be Dispensed`, err);
        this.closeConfirmDispenseModal();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  showDispenseErrorMessage = async () => {
    this.setState({ showDispenseErrorMessage: true });
  };

  hideDispenseErrorMessage = async () => {
    this.setState({ showDispenseErrorMessage: false });
  };

  getFields = () => {
    const { selectedOrders, activeTab } = this.state;
    return [
      {
        name: 'shipped_date',
        displayName: 'Date Shipped',
        inputFilterable: false,
        sortable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        sortFieldName: 'shipped_date',
        render: (e) => <span>{e.value}</span>,
      },
      {
        name: 'order_code',
        displayName: 'Order Id',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
      },
      {
        name: 'order_date',
        displayName: 'Order Placed',
        inputFilterable: false,
        sortable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        sortFieldName: 'order_date',
        render: (e) => moment(new Date(e.value)).format('DD-MMM-YYYY'),
      },
      {
        name: 'patient_id',
        displayName: 'Patient ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
      },
      {
        name: 'patient_name',
        displayName: 'Patient',
        inputFilterable: true,
        tdClassName: 'sub-text',
        sortable: true,
        sortFieldName: 'Patient.PatientUser.first_name',
      },
      {
        name: 'gp_name',
        displayName: 'Doctor',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'GeneralPractitioner.Doctor.first_name',
        render: (e) => (
          <ul style={{ listStyle: 'none' }}>
            {e.record.gps.map((x, idx) => (
              <li key={idx}>{x}</li>
            ))}
          </ul>
        ),
      },
      {
        name: 'approvals',
        displayName: 'Approvals',
        inputFilterable: true,
        tdClassName: 'sub-text',
        render: (e) => (
          <ul style={{ listStyle: 'none', textAlign: 'center', cursor: 'pointer' }}>
            <li
              onClick={() =>
                this.setState({ showApprovalDocumentsModal: true, orderSelectedForApprovalDocumentsModal: e.record })
              }
            >
              <PictureAsPdfIcon />
            </li>
          </ul>
        ),
      },
      {
        name: 'prescriptions',
        displayName: 'Prescription',
        inputFilterable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        render: (e) => (
          <ul style={{ listStyle: 'none' }}>
            {/* eslint-disable react/jsx-no-target-blank */}
            {e.value.map((prescription, idx) => (
              <li key={idx}>
                <a href={prescription.path} target="_blank">
                  {prescription.name}
                </a>
              </li>
            ))}
          </ul>
        ),
      },
      {
        name: 'product_name',
        displayName: 'Product',
        inputFilterable: true,
        tdClassName: 'sub-text',
        render: (e) => (
          <ul style={{ listStyle: 'none' }}>
            {e.record.product_details?.map((x, idx) => (
              <li key={idx}>{x.name}</li>
            ))}
          </ul>
        ),
      },
      {
        name: 'quantity',
        displayName: 'Qty',
        inputFilterable: false,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        render: (e) => (
          <ul style={{ listStyle: 'none' }}>
            {e.record.quantity.map((x, idx) => (
              <li key={idx}>{x}</li>
            ))}
          </ul>
        ),
      },
      {
        name: 'repeats',
        displayName: 'Repeats',
        inputFilterable: false,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        render: (e) => (
          <ul style={{ listStyle: 'none' }}>
            {e.record.repeats.map((x, idx) => (
              <li key={idx}>{x}</li>
            ))}
          </ul>
        ),
      },
      {
        name: 'dispensed_date',
        displayName: 'Dispensed Date',
        inputFilterable: false,
        sortable: false,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        sortFieldName: 'dispensed_date',
        render: (e) => (
          <span>{e.value === '-' ? '-' : <span> {moment(new Date(e.value)).format('DD-MMM-YYYY')} </span>}</span>
        ),
      },
      {
        name: 'shipmentStatus',
        displayName: 'Status',
        inputFilterable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        render: (e) => {
          let status = e.value;
          if (e.value === 'Pharmacy out of Stock') {
            status = 'Out of Stock';
          }
          if (e.value === 'Pharmacy Partially out of Stock') {
            status = 'Partially out of Stock';
          }
          if (e.value === 'Unable to Dispense') {
            status = (
              <div style={{ display: 'flex', width: '87px' }}>
                <a onClick={() => this.getUnableToDispenseInfo(e.record.id)} style={{ paddingRight: '4px' }}>
                  <BsInfoCircle style={{ marginTop: '14px' }} />
                </a>
                <span>{e.value}</span>
              </div>
            );
          }
          return status;
        },
      },
      {
        name: 'shipmentStatus',
        displayName: '',
        inputFilterable: true,
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        visible: true,
        render: (el) => {
          if (el.value === 'Processing Dispense') {
            return (
              <button
                type="button"
                className="btn-unable-dispense"
                onClick={() => {
                  this.showSplitOrderModal(el.record.id);
                }}
                target="_blank"
              >
                Unable to Dispense
              </button>
            );
          }
          return '';
        },
      },
      {
        name: 'id',
        displayName: 'Select',
        tdClassName: 'sub-text text-center',
        thClassName: 'text-center',
        visible: activeTab !== tabs.PROCESSING_DISPENSE,
        render: (e) => {
          return (
            <>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={selectedOrders.includes(e.value)}
                  onChange={(evt) => this.selectOrder(evt)}
                  value={e.value}
                  id={`pharmacist-order-${e.value}`}
                />
                <label className="custom-control-label" htmlFor={`pharmacist-order-${e.value}`}>
                  {}
                </label>
              </div>
              <SameDayDeliveryChip potentialSameDayDelivery={e.record.potential_same_day_delivery} sx={{ mt: 2 }} />
            </>
          );
        },
      },
    ];
  };

  render() {
    const {
      loading,
      selectedOrder,
      selectedAlpat,
      activeTab,
      filter,
      modelShow,
      selectedOrders,
      selectedProducts,
      orders,
      selectedReasonStatus,
      reasonStatus,
      sortingOrder,
      count,
      page,
      pageSize,
      unableToDispenseData,
      showConfirmDispenseModal,
      showApprovalDocumentsModal,
      orderSelectedForApprovalDocumentsModal,
      processingDispenseCount,
      disabled,
      showDispenseErrorMessage,
    } = this.state;

    const customStyles = {
      content: {
        ...(showConfirmDispenseModal
          ? {
              width: '30%',
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
            }
          : {
              width: '35%',
              position: 'absolute',
              inset: '50% auto auto 50%',
              border: '1px solid rgb(204, 204, 204)',
              background: 'rgb(255, 255, 255)',
              overflow: 'auto',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px',
              display: 'contents',
            }),
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    if (loading || this.props.loading) {
      return <CircleLoader />;
    }

    return (
      <div className="container-fluid">
        <h3>Orders</h3>

        {/* Table Actions */}
        <div className="row align-items-center">
          <div className="col-sm-4">
            <div className="btn-group" role="group">
              <button
                type="button"
                className={activeTab === tabs.OPEN_ORDER_OVERVIEW ? 'btn btn-primary' : 'btn btn-outline-primary'}
                onClick={() => this.orderToggleTab(tabs.OPEN_ORDER_OVERVIEW)}
              >
                Open Orders
              </button>
              <button
                type="button"
                className={activeTab === tabs.PROCESSING_DISPENSE ? 'btn btn-primary' : 'btn btn-outline-primary'}
                onClick={() => this.orderToggleTab(tabs.PROCESSING_DISPENSE)}
              >
                Processing Dispense
              </button>
              <button
                type="button"
                className={activeTab === tabs.DISPENSED_ORDER_OVERVIEW ? 'btn btn-primary' : 'btn btn-outline-primary'}
                onClick={() => this.orderToggleTab(tabs.DISPENSED_ORDER_OVERVIEW)}
              >
                Dispensed Orders
              </button>
              <button
                type="button"
                className={activeTab === tabs.ALL_ORDER_OVERVIEW ? 'btn btn-primary' : 'btn btn-outline-primary'}
                onClick={() => this.orderToggleTab(tabs.ALL_ORDER_OVERVIEW)}
              >
                All Orders
              </button>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="d-flex mt-lg-2 flex-wrap justify-content-lg-end">
              <div className="d-flex mt-3 mt-lg-0">
                {filter.toLowerCase() === 'awaiting dispensing' && (
                  <Button
                    text="Select All"
                    className="btn btn-md btn-primary mx-1"
                    onClick={() => this.selectAllOrders()}
                  />
                )}
                {!activeTab === tabs.PROCESSING_DISPENSE && (
                  <Button
                    text="Select All"
                    className="btn btn-md btn-primary mx-1"
                    onClick={() => this.selectAllOrders()}
                  />
                )}
                {activeTab !== tabs.PROCESSING_DISPENSE && (
                  <Button
                    text="Export All"
                    className="btn btn-md btn-primary mx-1"
                    onClick={() => this.generateExcelSheet()}
                  />
                )}
                {activeTab === tabs.OPEN_ORDER_OVERVIEW && (
                  <Button
                    text="Process Dispense"
                    className="btn btn-md btn-primary mx-1"
                    onClick={() => this.dispenseOrders()}
                  />
                )}
              </div>
              {activeTab === tabs.PROCESSING_DISPENSE && (
                <Button
                  text="Confirm Dispense"
                  className="btn btn-md btn-primary mx-1"
                  onClick={() => this.openConfirmDispenseModal()}
                />
              )}
            </div>
            {activeTab !== tabs.PROCESSING_DISPENSE && (
              <div className="d-flex mt-lg-2 flex-wrap justify-content-lg-end">
                <p className="mr-5 mb-0" style={{ fontSize: 10 }}>
                  Selected: {selectedOrders.length}
                </p>
              </div>
            )}
          </div>
        </div>

        <div
          className={`alert alert-danger alert-dismissible mt-3 ${
            showDispenseErrorMessage ? 'display-block' : 'display-none'
          }`}
        >
          <BsFillExclamationCircleFill className="mr-2" />
          <strong>Some of your orders did not go through.</strong>
          <span className="ml-1">
            Expect an email with the failed Order IDs and please get in touch with our support team at
            pharmacy-ops@montu.com.au for a quick resolution.
          </span>
          <button
            onClick={() => this.hideDispenseErrorMessage()}
            type="button"
            className="close justify-content-lg-end"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        {this.props?.flags.pharmacy_filter_orders_by_status && (
          <Box className="mr-5 mt-2">
            <FilterByOrderStatusSelect
              statuses={[
                OrderStatus.AwaitingDispensing,
                OrderStatus.Pending,
                OrderStatus.Test,
                OrderStatus.IntervalTime,
                OrderStatus.PharmacyPartiallyOutOfStock,
                OrderStatus.UnableToDispense,
              ]}
              selectedStatus={this.state.filter}
              onSelect={this.onFilter}
            />
          </Box>
        )}
        {/* Table */}
        <FilterableTable
          className="table-responsive d-lg-table mt-3"
          trClassName={(e) => this.priorityCheck(e)}
          data={orders}
          loading={loading}
          fields={this.getFields()}
          paginationOnServer
          initialSort={sortingOrder.name}
          initialSortDir={!sortingOrder.reverse}
          onPageChanged={this.onPageChange}
          filterData={this.onFilter}
          serverSort={this.getSortColumn}
          totalRecords={count}
          currentPage={page}
          pageSize={pageSize}
          filterPlaceholder={filter || 'Search'}
          pageSizes={false}
          maintainPageOnSetData
          topPagerVisible={false}
          noRecordsMessage="There are no record to display"
          noFilteredRecordsMessage="No record match your filters!"
        />
        <CustomModal isOpen={modelShow} style={customStyles} shouldCloseOnOverlayClick={false}>
          <>
            <div
              className="modal"
              id="splitOrderModal"
              aria-labelledby="splitOrdertModal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            />
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    Unable to Dispense {selectedOrder.order_code}{' '}
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.hideSplitOrderModal()}
                      style={{ position: 'absolute', right: '20px' }}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </h5>
                  <hr />
                </div>
                <div className="modalbody" style={{ margin: '10px' }}>
                  <p style={{ marginLeft: '8px' }}>Mark the reason why this order cannot be dispensed</p>
                  <table className="table table-hover unabledispense">
                    <thead>
                      <tr>
                        <th>Paid Date</th>
                        <th className="custom-align-center">Patient ID</th>
                        <th className="custom-align-center">Prescription</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{selectedOrder.shipped_date}</td>
                        <td>{selectedOrder.patient_id}</td>
                        <td> {selectedAlpat} </td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <div style={{ maxHeight: '280px', overflowY: 'auto' }}>
                    <table className="table table-hover unabledispense">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th className="custom-align-center">Qty</th>
                          <th className="custom-align-center">Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProducts.map((product) => (
                          <tr>
                            <td>{product.name}</td>
                            <td>{product.qty}</td>
                            <td className="custom-align-center">
                              <Select
                                name="unable-to-dispense"
                                value={selectedReasonStatus.find((item) => item.product === product)?.reason}
                                isSearchable
                                placeholder="Select"
                                className="select-field"
                                options={reasonStatus}
                                style={customSelectStyles}
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                onChange={(e) => this.handleSelectedPharmacistStatus(e, product)}
                              />
                              {this.showOtherReason(product) && (
                                <FormInput
                                  type="text"
                                  noLabel
                                  placeholder="Issue"
                                  name="reason"
                                  className="mb-2"
                                  onChange={(e) => this.handleOtherReasonChange(e, product)}
                                  style={{ margin: '10px 0px' }}
                                  maxLength={100}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Additional comments</th>
                        </tr>
                        <tr>
                          <FormInput
                            type="text"
                            nolabel
                            placeholder="Any additional comments relating to an issue..."
                            name="additionalComment"
                            onChange={(e) => this.handleAdditionalCommentChange(e)}
                            style={{ margin: '10px 10px', resize: 'none' }}
                            rows={3}
                            maxlength={255}
                          />
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div className="modalfooter" style={{ margin: '0px 20px 10px 20px' }}>
                  <Button
                    text="Confirm"
                    type="submit"
                    className="btn btn-primary float-right mt-5"
                    disabled={disabled}
                    onClick={this.saveUnableToDispense}
                  />
                  <Button
                    text="Cancel"
                    type="submit"
                    className="btn btn-outline-primary float-right mt-5 mr-3"
                    onClick={this.hideSplitOrderModal}
                  />
                </div>
              </div>
            </div>
          </>
        </CustomModal>
        <UnableToDispenseModal
          unableToDispenseData={unableToDispenseData}
          hideUnableToDispenseModal={this.hideUnableToDispenseModal}
        />

        <CustomModal isOpen={showConfirmDispenseModal} style={customStyles} ariaHideApp={false}>
          <div className="confirm-modal-font">
            <div className="modal-header d-block">
              <button type="button" className="close" onClick={() => this.closeConfirmDispenseModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
              <h3 className="modal-title font-weight-bold">Confirm Dispense</h3>
            </div>

            <div className="modal-body">
              <p>
                Are you sure you want to dispense {processingDispenseCount} order{processingDispenseCount > 1 && 's'}?
              </p>
            </div>
            <Button
              text="Confirm"
              className="btn btn-md btn-primary d-block mx-auto"
              onClick={() => this.confirmDispense()}
              disabled={loading}
            />
          </div>
        </CustomModal>

        {showApprovalDocumentsModal && (
          <PharmacistApprovalDocumentsModal
            show={showApprovalDocumentsModal}
            data={orderSelectedForApprovalDocumentsModal}
            close={() => this.setState({ showApprovalDocumentsModal: false })}
          />
        )}
      </div>
    );
  }
}

export default PharmacistOrder;
