import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { usePharmacistConfirmDispenseModal } from '@/context/pharmacist/orders/PharmacistOrdersModals';

function ConfirmDispenseModal() {
  const { confirmDispenseModal, confirmDispense, hideConfirmDispenseModal, isSubmitting } =
    usePharmacistConfirmDispenseModal();

  return (
    <Dialog open={confirmDispenseModal.visible} onClose={hideConfirmDispenseModal}>
      <DialogTitle>Confirm dispense</DialogTitle>
      <DialogContent>
        Are you sure you want to dispense {confirmDispenseModal.data} order{confirmDispenseModal.data > 1 && 's'}
      </DialogContent>
      <DialogActions>
        <Button color="info" onClick={hideConfirmDispenseModal} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button color="primary" onClick={confirmDispense} disabled={isSubmitting}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDispenseModal;
